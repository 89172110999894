import { Divider } from 'antd';
import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import SvgIcon from '~/components/SvgIcon';
import { SidebarWrapper } from './styled';
import { ROUTE_PATH } from '~/routes/route.constant';
import { useMutation } from '@apollo/client';
import { LOG_OUT } from '~/graphql/Authentication.graphql';

const Sidebar = () => {
  const navigate = useNavigate();
  const [tabActive, setTabActive] = useState(1);
  const { pathname } = useLocation();
  const [logout] = useMutation(LOG_OUT, {
    onCompleted: () => {
      navigate(ROUTE_PATH.LOGIN);
      localStorage.clear();
    }
  });

  useEffect(() => {
    if (pathname.indexOf('/blog') !== -1) setTabActive(1);
    else setTabActive(2);
  }, [pathname]);

  return (
    <SidebarWrapper>
      <div className='rounded-lg bg-[#F8F8F8] px-4 m-7'>
        <div className='flex items-center h-12 gap-2'>
          <SvgIcon name='icon-user' />
          Admin
        </div>
        <Divider />

        <div
          onClick={() => setTabActive(1)}
          className={`flex h-12 items-center  cursor-pointer pl-5 ${
            tabActive === 1 && 'text-[#FF5400]'
          }`}
        >
          <Link to={ROUTE_PATH.BLOG_MANAGEMENT}>Blogs</Link>
        </div>
        <Divider />
        <div
          className={`flex h-12  items-center cursor-pointer pl-5 ${
            tabActive === 2 && 'text-[#FF5400]'
          }`}
        >
          {/* <Link to={'#'}>Careers</Link> */}
        </div>

        <Divider />
        <div
          onClick={logout}
          className='flex h-12  items-center cursor-pointer gap-2 text-[#FF4D4F]'
        >
          <SvgIcon name='icon-logout' />
          Log out
        </div>
      </div>
    </SidebarWrapper>
  );
};

export default Sidebar;
