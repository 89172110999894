import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';

const RouteMetadata = () => {
  const location = useLocation();

  // Ánh xạ URL đến metadata
  const metadataMap = {
    '/': {
      title: 'Top Development Hub In Asia For SMEs & Startups | 1BITLAB',
      description: '1BITLAB delivers the full-cycle software development services. We are a cutting-edge technology company, committed to delivering software solutions in diverse industries with agility, top-notch quality, and cost efficiency.',
      keywords: ''
    },
    '/about-us': {
      title: 'About Us | 1BITLAB',
      description: '',
      keywords: ''
    },
    '/managed-services': {
      title: 'Managed Services | 1BITLAB',
      description: '',
      keywords: ''
    },
    '/career': {
      title: 'Career | 1BITLAB',
      description: '',
      keywords: ''
    },
    '/explore-news': {
      title: 'News | 1BITLAB',
      description: '',
      keywords: ''
    },
    '/Portfolio': {
      title: 'Portfolio | 1BITLAB',
      description: '',
      keywords: ''
    },
    '/contact-us': {
      title: 'Contact Us | 1BITLAB',
      description: '',
      keywords: ''
    },
    '/expertise': {
      title: 'Expertises | 1BITLAB',
      description: '',
      keywords: ''
    },
    '/on-demand-software-development': {
      title: 'On-demand Software Development | 1BITLAB',
      description: '',
      keywords: ''
    },
    '/remote-software': {
      title: 'Remote Software | 1BITLAB',
      description: '',
      keywords: ''
    },
    '/r&d-solutions': {
      title: 'R&D Solutions | 1BITLAB',
      description: '',
      keywords: ''
    },
    '/game-development': {
      title: 'Game Development | 1BITLAB',
      description: '',
      keywords: ''
    },
    '/detail-portfolio': {
      title: 'Portfolio | 1BITLAB',
      description: '',
      keywords: ''
    },
    '/tech-blogs': {
      title: 'Tech Blogs | 1BITLAB',
      description: '',
      keywords: ''
    }
  };

  const metadata = metadataMap[location.pathname] || {
    title: 'Page Not Found',
    description: 'The page you requested does not exist.',
    keywords: '404, not found'
  };

  return (
    <Helmet>
      <title>{metadata.title}</title>
      <meta name="description" content={metadata.description} />
      <meta name="keywords" content={metadata.keywords} />
    </Helmet>
  );
};

export default RouteMetadata;