import { useLazyQuery } from '@apollo/client';
import { Button, Col, Input, Row, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { Helmet } from 'react-helmet-async';
import {
  GET_BLOG_CATEGORIES,
  GET_BLOG_DETAIL,
  GET_OTHER_BLOGS,
  GET_TAGS
} from '~/graphql/Blog.graphql';
import { LayoutCommon } from '~/styles/genaralStyled';

import { BlogDetailWrapper } from './styled';
import { ROUTE_PATH } from '~/routes/route.constant';
import queryString from 'query-string';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import { DATE_FORMAT_DD_MM_YYYY_WITH_SLASH } from '~/common/constants';

function BlogDetail() {
  const { search } = useLocation();
  const navigate = useNavigate();
  const [mainNew, setMainNew] = useState({});
  const [tags, setTags] = useState([]);
  const [blogCategories, setBlogCategories] = useState([]);
  const [initValues, setInitValues] = useState({
    search: '',
    tag: undefined,
    category: undefined
  });

  const [getDetail, { loading }] = useLazyQuery(GET_BLOG_DETAIL, {
    variables: {
      blogDetailId: search.slice(1)
    },
    onCompleted: (data) => {
      setMainNew(data.blogDetail);
      window.scrollTo(0, 0);
    }
  });

  const [getTags, { loading: loadingTags }] = useLazyQuery(GET_TAGS, {
    onCompleted: (data) => {
      const listTags = data.tags.map((item) => {
        return {
          value: item.title,
          label: item.title
        };
      });
      setTags(listTags);
    }
  });
  const [getOtherBlogs, { data: otherBlogs, loading: loadingOtherBlogs }] =
    useLazyQuery(GET_OTHER_BLOGS, {
      variables: {
        getOtherBlogsId: search.slice(1)
      }
    });

  const [getBlogCategories, { loading: loadingCategories }] = useLazyQuery(
    GET_BLOG_CATEGORIES,
    {
      onCompleted: (data) => {
        const categories = data.blogCategories.map((item) => {
          return {
            value: item.id,
            label: item.title
          };
        });
        setBlogCategories(categories);
      }
    }
  );

  const getDetailBlog = async () => {
    await Promise.all([getTags(), getBlogCategories(), getDetail()]);
  };
  const onSearch = (input) => {
    navigate(
      `${ROUTE_PATH.TECH_BLOGS}?${queryString.stringify({
        ...initValues,
        ...input
      })}`
    );
  };

  useEffect(() => {
    getDetailBlog();
    getOtherBlogs();
  }, []);


  return (
    <div>
      {/* Use Helmet to dynamically set the metadata */}
      <Helmet>
        <title>{mainNew.metaTitle}</title>
        <meta name='description' content={mainNew.metaDescription} />
        <meta name='keywords' content={mainNew.metaKeywords} />
      </Helmet>

      <BlogDetailWrapper>
        <Spin
          spinning={
            loading || loadingCategories || loadingTags || loadingOtherBlogs
          }
        >
          <LayoutCommon>
            <div className='xl:mt-[85px] mt-5 mb-10'>
              {/* <div className='text-center flex'>
              <div className='capability'>{mainNew?.tags?.join(', ')}</div>
              <div>
                {dayjs(mainNew?.createdAt).format(
                  DATE_FORMAT_DD_MM_YYYY_WITH_SLASH
                )}
              </div>
            </div> */}
              <div className='text-4xl text-center font-semibold text-[#373737] leading-[59px] mt-4 mb-[30px] line-clamp-2'>
                {mainNew?.title}
              </div>
              {mainNew?.image && (
                <div className='w-full max-w-[900px] mx-auto'>
                  <img
                    src={process.env.REACT_APP_API_UPLOAD + mainNew?.image}
                    alt=''
                    className='aspect-video w-full'
                  />
                </div>
              )}
            </div>
            <Row gutter={24} className='mb-10'>
              <Col xl={18} md={18} sm={24} xs={24}  >
                <div className='contentBlog leading-[1.85]' dangerouslySetInnerHTML={{ __html: mainNew?.content }} />
              </Col>

              <Col xl={6} md={6} sm={24} xs={24}>
                <div>
                  <div className='shadow-[0_5px_5px_0_#0000001A] mb-[33px] h-10'>
                    <Input
                      placeholder='Keyword...'
                      value={initValues.search}
                      onChange={(e) =>
                        setInitValues({
                          ...initValues,
                          search: e.target.value
                        })
                      }
                      suffix={
                        <Button
                          onClick={() => onSearch()}
                          className='button-custom'
                        >
                          Search
                        </Button>
                      }
                      onKeyDown={(event) => {
                        if (event.key === 'Enter') onSearch();
                      }}
                    />
                  </div>
                  <div className='stack-tag'>CATEGORIES</div>
                  <ul className='ml-6 list-disc columns-1 md:columns-2 xl:columns-1'>
                    <li
                      key={'undef'}
                      className={`leading-6 cursor-pointer ${
                        initValues.category === undefined && 'text-[#FF5400]'
                      }`}
                      onClick={() => {
                        onSearch({ category: undefined });
                      }}
                    >
                      All Categories
                    </li>
                    {blogCategories.map((category) => (
                      <li
                        key={category.value}
                        className={`leading-6 cursor-pointer ${
                          initValues.category === category.value &&
                          'text-[#FF5400]'
                        }`}
                        onClick={() => {
                          onSearch({ category: category.value });
                        }}
                      >
                        {category.label}
                      </li>
                    ))}
                  </ul>
                </div>
                <div className='stack-tag mt-10'>TAGS</div>
                <div className='flex flex-wrap gap-4'>
                  {tags.map((tag) => (
                    <div
                      key={tag.value}
                      className={`px-3 py-2 cursor-pointer flex  items-center rounded-[20px] border border-solid border-[#D9D9D9] ${
                        initValues.tag === tag.value &&
                        'text-[#FF5400] border-[#FF5400]'
                      }`}
                      onClick={() => {
                        onSearch({ tag: tag.value });
                      }}
                    >
                      {tag.label}
                    </div>
                  ))}
                </div>
              </Col>
            </Row>
            <div className='text-[36px] font-semibold mb-4'>Other Blogs</div>
            <div className='w-full mb-10'>
              <Splide
                aria-label='My Favorite Images'
                options={{
                  width: '100%',

                  arrows: true,
                  pagination: false,
                  lazyLoad: true,
                  perPage: 3,
                  gap: 24,
                  breakpoints: {
                    1024: {
                      perPage: 2
                    },
                    600: {
                      perPage: 1
                    }
                  }
                }}
              >
                {otherBlogs?.getOtherBlogs?.map((item, idx) => {
                  return (
                    <SplideSlide key={idx}>
                      <div
                        className='shadow-[0px_1px_10px_0px_#0000001A] rounded-[10px] cursor-pointer xl:h-full'
                        onClick={() =>
                          navigate({
                            pathname: ROUTE_PATH.TECH_BLOGS_DETAIL,
                            search: item?.id
                          })
                        }
                      >
                        <img
                          src={process.env.REACT_APP_API_UPLOAD + item?.image}
                          alt=''
                          className='h-[225px] rounded-t-[10px] w-full object-cover'
                        />

                        <div className='px-4 pt-[26px] pb-[20px]'>
                          <div className='mb-4 text-xl font-semibold text-[#373737] leading-[24.72px] line-clamp-2'>
                            {item?.title}
                          </div>
                          <div className='flex'>
                            <div
                              className={`mb-5 text-[#FF5400] relative mr-12 after:absolute after:top-1/2 after:-right-16 after:-translate-x-1/2 after:content-[''] after:w-10 after:h-[1px] after:bg-[#FF5400]`}
                            >
                              {item?.category?.title}&nbsp;
                            </div>
                            <div>
                              {dayjs(item?.createdAt).format(
                                DATE_FORMAT_DD_MM_YYYY_WITH_SLASH
                              )}
                            </div>
                          </div>
                          <div
                            className='line-clamp-3 img-none leading-[1.85]'
                            dangerouslySetInnerHTML={{
                              __html: item?.content
                            }}
                          ></div>
                        </div>
                      </div>
                    </SplideSlide>
                  );
                })}
              </Splide>
            </div>
          </LayoutCommon>
        </Spin>
      </BlogDetailWrapper>
    </div>
  );
}

export default BlogDetail;
